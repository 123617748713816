.class-brief-detail {
    width: 100%;

    .train-car-detail-top {
        padding: 30px 0;
        box-sizing: border-box;
        display: flex;
        justify-content: center;

        .train-detail-top-content {
            width: 1200px;
            min-width: 1200px;
            flex: none;

            .breadcrumb-btn {
                // font-family: PingFang SC;
                font-size: 14px;
                font-weight: 400;
                cursor: pointer;
                color: #122431;
            }
            .breadcrumb-btn:hover {
                color: #E9623E;
            }
        }
    }

    .train-car-detail-bottom {
        padding: 30px 0 60px;
        height: 20px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;

        .train-car-detail-bottom-content {
            width: 1200px;
            min-width: 1200px;
            flex: none;

            .next-text {
                cursor: pointer;
                // font-family: PingFang SC;
                font-size: 16px;
                font-weight: 600;
                color: #458DD2;
            }
        }
    }

    .train-car-detail-content {
        width: 100%;
        background-color: #fff;
        padding: 30px 0 40px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;

        .train-car-detail-shell {
            width: 1200px;
            min-width: 1200px;
            flex: none;
        }

        .train-car-detail-info {
            display: flex;

            .train-detail-info-img {
                width: 262px;
                height: 197px;
                border-radius: 15px;
                overflow: hidden;
                margin-right: 29px;
                position: relative;
                .train-detail-info-img-swiper {
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .class-swiper-pages {
                    position: absolute;
                    bottom: 10px;
                    right: 10px;
                    min-width: 60px;
                    height: 30px;
                    border-radius: 20px;
                    background: rgba(0, 0, 0, 0.6);
                    z-index: 99999;
                    text-align: center;
                    color: #fff;
                    line-height: 30px;
                }
            }

            .train-detail-info-right {
                flex: 1;
                margin-top: 21px;


                .info-right-top {
                    display: flex;
                    justify-content: space-between;
                    // align-items: center;

                    .info-top-title {
                        // font-family: PingFang SC;
                        // display: flex;
                        // align-items: center;
                        font-size: 36px;
                        font-weight: 600;
                        color: #101D37;
                    }

                    .course-name {
                        border: 1px solid rgba(233, 98, 62, 1);
                        background-color: #FFF6F1;
                        color: #E9623E;
                        // font-family: FZLanTingHeiS-DB1-GB;
                        font-size: 14px;
                        font-weight: bold;
                        display: inline-block;
                        padding: 4.5px 8px;
                        border-radius: 4px;
                        margin-left: 10px;
                        transform: translate(0, -9px);
                    }

                    .info-top-vehicle {
                        display: flex;
                        margin-top: 17px;
                        .class-tab-item {
                            display: inline-block;
                            border-radius: 3px;
                            padding: 0 5px;
                            height: 20px;
                            box-sizing: border-box;
                            // font-family: FZLanTingHeiS-R-GB;
                            font-family: 微软雅黑;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 20px;
                            color: #666666;
                            background-color: #EFF3F5;
                        }
                    }

                    .info-right-top-btn {
                        width: 242px;
                        height: 60px;
                        border-radius: 30px;
                        // font-family: PingFang SC;
                        font-size: 20px;
                        font-weight: bold;
                        line-height: 60px;
                        text-align: center;
                        color: #fff;
                        background-color: #E9623E;
                    }

                    .info-right-top-btn:hover {
                        background-color: #D14F2C;
                    }
                }

                .info-contacts {
                    display: flex;
                    margin-top: 18px;
                    .carType-icon {
                        font-size: 18px;
                        color: #BEC2C9;
                    }   

                    .info-contacts-content {
                        margin-left: 11px;
                        font-size: 16px;
                        line-height: 18px;
                        color: #666666;
                    }
                }

                .class-info-money {
                    margin-top: 13px;
                    // font-family: PingFang SC;
                    font-family: 微软雅黑;
                    font-size: 28px;
                    font-weight: bold;
                    color: #E9623E;
                }
            }
        }

        .train-car-synopsis {
            margin-top: 60px;

            .synopsis-title {
                // font-family: PingFang SC;
                font-size: 36px;
                font-weight: 600;
                color: #101D37;
                border-bottom: 1px dashed #D9D9D9;
                padding-bottom: 18px;
                box-sizing: border-box;
                
            }

            .synopsis-content {
                margin-top: 12px;
                // font-family: PingFang SC;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                color: #101D37;
            }

            .new-text {
                margin-top: 12px;
                img {
                    max-width: 100%;
                    display:block;
                }
                // p{
                //     text-align: center;
                // }
            }
        }

        .train-car-imgs {
            margin-top: 30px;
            height: 513px;
            border-radius: 15px;
            display: flex;
            overflow: hidden;
            position: relative;
            background-color: rgba(0, 25, 49, 0.6995);

            .swiper-button-next,
            .swiper-button-prev {
                opacity: 0;
            }

            .train-mySwiper2 {
                height: 100%;
                width: 913px;
                flex: none;
            }

            .train-my-swiper {
                height: 100%;
                width: 287px;
                box-sizing: border-box;
                // background-color: rgba(0, 25, 49, 0.6995);
                padding: 15px 0;
                box-sizing: border-box;
            }

            .train-my-Swiper {
                height: 100%;
                width: 287px;
                overflow: auto;

                .swiper-wrapper {
                    transition: all 0.2s ease-in-out;
                    display: flex;
                    flex-direction: column;
                    object-fit: cover;
                }

                // margin-top: 160px;
                // box-sizing: border-box;
                // background-color: rgba(0, 25, 49, 0.6995);
                // padding: 15px 0;
                // box-sizing: border-box;

                .my-Swiper-slide {
                    width: 258px !important;
                    height: 140px !important;
                    margin: 5px 0 5px 4px;

                    >img {
                        width: 224px;
                        height: 126px;
                        border-radius: 10px;
                        margin: 7px 0 5px 27px;
                        cursor: pointer;
                    }
                }

                .my-swiper-slide-active {
                    background: url(https://img.58cdn.com.cn/dist/jxt/images/jxtschool/swiper-slide-thumb-active.png) center no-repeat;
                    background-size: cover;
                }
            }

            .train-my-Swiper::-webkit-scrollbar {
                display: none;
            }

            .swiper-slide img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .train-car-imgs-page {
                position: absolute;
                bottom: 20px;
                left: 20px;
                min-width: 78px;
                padding: 0 16px;
                box-sizing: border-box;
                height: 42px;
                border-radius: 21px;
                text-align: center;
                // font-family: PingFang SC;
                font-size: 16px;
                font-weight: bold;
                line-height: 42px;
                color: #fff;
                background-color: rgba(0, 25, 49, 0.8);
                z-index: 99;
            }
        }

        .train-car-map {
            height: 260px;
            margin-top: 30px;
            overflow: hidden;
            border-radius: 15px;
            position: relative;

            >#train-car-map {
                margin-bottom: -40px;
                height: 300px;
            }

            .train-car-map-tip {
                position: absolute;
                top: 75px;
                left: calc(1200px - 65%);
                padding: 0 20px;
                box-sizing: border-box;
                height: 58px;
                // font-family: PingFang SC;
                font-size: 16px;
                font-weight: 600;
                line-height: 58px;
                text-align: center;
                color: #fff;
                background-color: #E9623E;
                border-radius: 15px;
            }


        }

        // .train-car-map::after {
        //     content: '';
        //     width: 16px;
        //     height: 16px;
        //     background-color: #E9623E;
        //     transform: rotate(45deg);
        //     position: absolute;
        //     top: 124px;
        //     left: calc(1200px - 609px);
        // }
    }
}