.baidu-map {
    position: relative;
    border: 3px solid #FFF;
    border-radius: 20px;
    overflow: hidden;
    .BMap_bubble_pop {
        background: linear-gradient(#FE8335, #E9623E) !important;
        border: none !important;
    }

   
  
     
        .place_box {
            width: 850px;
            height:85px;
            overflow-x: auto;
            position: absolute;
            white-space: nowrap;
            z-index: 99;
            left: 20px;
            bottom: 10px;
            .place_item {
                display: inline-block;
                padding: 10px 10px 0 20px;
                background-color: #FFF;
                width: 300px;
                height: 70px;
                margin-right: 20px;
                border-radius: 10px;

                .place_name {
                    // font-family: PingFang SC;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 30px;
                    letter-spacing: 0px;
                    text-align: left;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    width: 250px;
                    white-space: nowrap;
                }

                .place_desc {
                    // font-family: PingFang SC;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 24px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    width: 250px;
                    white-space: nowrap;

                }
            }

            .place_item_active {
                color: #FFFF;
                background: linear-gradient(99.39deg, #FE8335 13.84%, #E9623E 96.33%);
            }
        }

        .place_box{
            ::-webkit-scrollbar{
                display: none;
            }
        }
        // .place_box:hover{
            .place_box::-webkit-scrollbar{
                height: 5px;
                background-color: #f5f5f5;
            }
            .place_box::-webkit-scrollbar-thumb{
                height: 5px;
                background-color: #999999;
            }
        // }
       


}