.header-style {
    height: 122px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    min-width: 1200px;
    font-family: 微软雅黑 !important;
}

.content-style {
    background-color: rgb(248 248 248);
    // min-height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 1200px;
    font-family: 微软雅黑 !important;
}

.footer-style {
    background-color: #fff;
    height: 508px;
    background: url(https://img.58cdn.com.cn/dist/jxt/images/jxtschool/foter_bg.png) no-repeat;
    background-size: cover;
    padding: 0;
    min-width: 1200px;
    font-family: 微软雅黑 !important;
}

.home-header {
    min-width: 1200px;
    width: 1200px;
    height: 127px;
}

.fiexd-container {
    position: fixed;
    top: calc(50% - 185px);
    right: 30px;
    z-index: 9999;

    .fiexd-container-top {
        width: 170px;
        height: 286px;
        box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.07);
        border-radius: 20px;
        padding: 22px 23px;
        box-sizing: border-box;
        background-color: #fff;

        .fiexd-container-top-item {
            text-align: center;

            .fiexd-wxcode-img {
                width: 124px;
                height: 124px;
            }

            .fiexd-wx-text {
                // font-family: FZLanTingHeiS-R-GB;
                font-family: 微软雅黑;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                text-align: center;
                color: #101D37;
                position: relative;
                padding-bottom: 12px;
            }

            .fiexd-wx-text::after {
                content: '';
                height: 1px;
                background-color: rgba(239, 243, 245, 1);
                width: 113px;
                position: absolute;
                left: 5px;
                bottom: 0;
            }
        }

        .fiexd-container-top-wraaper {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 48px;
            cursor: pointer;
            width: 170px;
            margin-left: -23px;

            >img {
                width: 24px;
                height: 24px;
            }

            .fiexd-container-text {
                // font-family: FZLanTingHeiS-R-GB;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                text-align: center;
                color: #101D37;
                position: relative;
                margin-left: 8px;
            }

            .fiexd-container-text-border::after {
                content: '';
                height: 1px;
                background-color: rgba(239, 243, 245, 1);
                width: 113px;
                position: absolute;
                left: -46px;
                bottom: -14px;
            }
        }

        .fiexd-container-top-wraaper:hover {
            .fiexd-container-text {
                color: #E9623E;
                // font-family: FZLanTingHeiS-DB1-GB;
                font-weight: bold;
            }
        }
    }

    .fiexd-container-toTop {
        width: 170px;
        height: 50px;
        background-color: #fff;
        border-radius: 25px;
        margin-top: 12px;
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.07);

        >img {
            width: 24px;
            height: 24px;
            // margin-left: 35px;
        }

        .fiexd-container-toTop-text {
            // font-family: FZLanTingHeiS-R-GB;
            font-size: 14px;
            font-weight: 400;
            color: #101D37;
            margin-left: 8px;
        }
    }

    .fiexd-container-toTop.fiexd-container-toTop-none {
        opacity: 0;
    }

    .fiexd-container-toTop:hover {
        .fiexd-container-toTop-text {
            color: #E9623E;
            // font-family: FZLanTingHeiS-DB1-GB;
            font-weight: bold;
        }
    }
}

.fiexd-application {
    padding: 20px 7px;

    .fiexd-application-title {
        // font-family: FZLanTingHeiS-R-GB;
        font-size: 14px;
        font-weight: 400;
        color: #727272;
        text-align: center;
    }

    .fiexd-application-phone {
        // font-family: FZLanTingHeiS-DB1-GB;
        font-size: 20px;
        font-weight: bold;
        color: #101D37;
        margin-top: 8px;
    }
}

.jxt-activity {
    position: fixed;
    z-index: 1000;
    bottom: 0;
    width: 100%;
    background: rgba(0, 0, 0, .6) !important;
    transition: all .5s;

    .activity-content {
        width: 1200px;
        height: 164px;
        margin: 0 auto !important;
        position: relative;

        .activity-img {
            position: absolute;
            bottom: 10px;

            img {
                -webkit-user-drag: none;
                width: 100%;
                height: 100%;
            }
        }

        .input-phone {
            position: absolute;
            right: 150px;
            top: 40%;
            .input-phone-box {
                display: flex;
                align-items: center;
                background-color: #FFF;
                border-radius: 20px;
                width: 272px;
                height: 42px;
            }
            .login-protect-text {
                color: #fff;
                margin-top: 8px;
                margin-left: 4px;
            }
            .input-div {
                .phone-input {
                    border-radius: 20px;
                    border: none;
                    outline: none;
                    background-color: #FFF;
                    text-align: left;
                    padding-left: 20px;
                    height: 40px;
                    width: 160px;
                    line-height: 40px;
                }
            }

            .activity-pick-btn {
                width: 120px;
                position: relative;
                bottom: -5px;
                right: -4px;
                cursor: pointer;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .jx-acticity-close {
            position: absolute;
            cursor: pointer;
            top: -12px;
            right: 0;
        }
    }


}

.show-jxt-activity {
    position: fixed;
    width: 170px;
    z-index: 1000;
    right: 20px;
    bottom: 50px;
    user-select: none;
    cursor: pointer;

    img {
        -webkit-user-drag: none;
        width: 100%;
        height: 100%;
    }
}

.preferential-bg {
    position: absolute;
    top: 122px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;

    .preferential-wrraper {
        width: 486px;
        height: 310px;
        margin: 298px auto 0;
        padding: 30px 0px 0;
        box-sizing: border-box;
        background-color: #fff;
        border-radius: 24px;

        .preferential-icon {
            font-size: 70px;
            color: #53C24E;
            margin: 0px auto 18px;
            display: block;
        }

        .preferential-title {
            font-size: 28px;
            text-align: center;
            color: #333333;
        }

        .preferential-text {
            font-size: 20px;
            color: #666666;
            text-align: center;
            margin: 15px 0 28px;
        }

        .preferential-btn {
            width: 220px;
            height: 60px;
            border-radius: 90px;
            font-size: 28px;
            line-height: 60px;
            text-align: center;
            color: #fff;
            margin: 0 auto;
            cursor: pointer;
            background: linear-gradient(91.13deg, #FF7A00 0.44%, #E9623E 99.49%);
        }
    }
}