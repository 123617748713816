.introduce-item {
    display: flex;
    justify-content: center;
    padding: 20px 0;
}

.introduce-item:hover {
    background-color: #F8F8F8;
}

.introduce-shell {
    display: flex;
    width: 1200px;
    min-width: 1200px;

    .introduce-user-img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        margin-right: 18px;
        overflow: hidden;
        flex: none;

        >img {
            width: 100%;
            height: 100%;
        }
    }

    .introduce-content {
        margin-top: 6px;
        flex: 1;

        .introduce-user-top {
            display: flex;
            justify-content: space-between;
        }

        .introduce-user-name {
            // font-family: FZLanTingHeiS-DB1-GB;
            font-family: 微软雅黑;
            font-size: 16px;
            font-weight: bold;
            color: #101D37;
        }

        .introduce-user-info {
            display: flex;
            align-items: center;
            margin-top: 5px;

            .coach-dingwei-img {
                width: 15px;
                height: 14px;

                >img {
                    width: 100%;
                    height: 100%;
                }
            }

            .coach-info-evaluate-text {
                margin-left: 10px;
                // font-family: FZLanTingHeiS-R-GB;
                font-family: 微软雅黑;
                font-size: 16px;
                font-weight: 400;
                color: #666666;
                margin-right: 13px;
                margin-top: 3px;

                .coach-info-text-number {
                    // font-family: FZLanTingHeiS-DB1-GB;
                    font-family: 微软雅黑;
                    font-size: 16px;
                    font-weight: bold;
                    color: #666666;
                }
            }

            .info-address {
                display: flex;

                .coach-tab-item {
                    border-radius: 3px;
                    padding: 0 8px;
                    height: 24px;
                    box-sizing: border-box;
                    // font-family: FZLanTingHeiS-R-GB;
                    font-family: 微软雅黑;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 26px;
                    color: #666666;
                    background-color: #EFF3F5;
                }
            }
        }

        .introduce-user-time {
            // font-family: FZLanTingHeiS-R-GB;
            font-family: 微软雅黑;
            font-size: 14px;
            font-weight: 400;
            color: #979797;
        }

        .introduce-text {
            margin-top: 15px;
            // font-family: FZLanTingHeiS-R-GB;
            font-family: 微软雅黑;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            word-break: break-all;
            // overflow: hidden;
            // display: -webkit-box;
            // -webkit-line-clamp: 2;
            // -webkit-box-orient: vertical;
        }
    }
}