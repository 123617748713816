.contactus-shell {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;


    .contactus-car-bg {
        width: 100%;
        min-width: 1200px;
        height: 300px;
        background-color: #EBF0F6;
        // background: url(https://img.58cdn.com.cn/dist/jxt/images/jxtschool/banner_bg_1.png?v=2) center no-repeat;
        // background-size: cover;
        display: flex;
        justify-content: center;
        > img {
            width: 1200px;
            min-width: 1200px;
            height: 100%;
        }
    }

    .contactus-container {
        display: flex;
        flex-direction: column;
        width: 1200px;
        padding: 60px 0;

        .contact-title {
            margin: 0px 0 30px 0;
            font-weight: 600;
            font-size: 30px;
        }

        .contact-box {
            display: flex;

            .contact-wechat {
                width: 300px;
                height: 400px;
                margin-right: 20px;

                .box {
                    border: 3px solid #FFF;
                    border-radius: 20px;
                    background: linear-gradient(#FFF6F3, #FFFFFF);
                    height: 400px;
                    display: flex;
                    flex-direction: column;
                    padding: 30px 0;
                    // justify-content: center;
                    align-items: center;

                    .username {
                        // font-family: PingFang SC;
                        font-size: 40px;
                        font-weight: 600;
                        line-height: 40px;
                        letter-spacing: 0px;
                        text-align: left;

                    }

                    .phone {
                        // font-family: PingFang SC;
                        font-size: 20px;
                        font-weight: 600;
                        line-height: 40px;
                        letter-spacing: 0px;
                        text-align: left;
                        color: #666666;
                        margin: 8px 0 0 0;
                    }

                    .wechat {
                        width: 200px;
                        height: 200px;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .scanf {
                        color: #666666;
                        // font-family: FZLanTingHeiS-R-GB;
                        font-size: 20px;
                        font-weight: 400;
                        line-height: 29px;
                        letter-spacing: 2.6031441688537598px;
                        text-align: center;

                    }
                }
            }

            .contact-map {
                width: calc(100% - 300px);
                height: 400px;
                // background-color: aqua;
                border-radius: 10px;
                overflow: hidden;
            }
        }

    }
}