.login-wraaper-bg {
    position: absolute;
    top: 122px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;

    .login-wraaper {
        width: 486px;
        height: 432px;
        margin: 128px auto 0;
        padding: 40px 23px 0;
        box-sizing: border-box;
        background-color: #fff;
        border-radius: 24px;
        position: relative;

        .guanbi-icon {
            position: absolute;
            top: 15px;
            right: 23px;
            font-size: 20px;
            color: #B5B5B5;
            cursor: pointer;
        }

        .login-logo {
            width: 136px;
            height: 30.91px;
            margin-top: -12px;
            margin: 0 auto;

            >img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .phone-input {
        width: 100%;
        height: 60px;
        padding-left: 65px;
        border-radius: 90px;
        font-size: 16px;
        background-color: #f8f8f8;
        box-sizing: border-box;
        border: none;
        outline: none;
        resize: none;
        -webkit-appearance: none;
    }
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button {
      opacity: 0;
    }
    .input-error-text {
        font-size: 12px;
        box-sizing: border-box;
        padding-left: 65px;
        height: 16px;
        margin: 7px 0;
        color: #F70204;
    }
    .cellPhone-icon {
        position: absolute;
        left: 31px;
        top: 17px;
        font-size: 25px;
        color: #A3A3A3;
    }
    .clear-icon {
        position: absolute;
        right: 28px;
        top: 20px;
        font-size: 20px;
        color: #C6C6C6;
        cursor: pointer;
    }
    .code-btn {
        position: absolute;
        right: 27px;
        top: 19px;
        font-size: 16px;
        color: #F87315;
        cursor: pointer;
    }
    .login-btn {
        width: 100%;
        height: 60px;
        border-radius: 90px;
        background: linear-gradient(91.13deg, #FF7A00 0.44%, #E9623E 99.49%);
        margin: 12px 0 16px;
        text-align: center;
        color: #fff;
        font-size: 28px;
        line-height: 60px;
        cursor: pointer;
    }
    .login-text {
        color: #999999;
        text-align: center;
        font-size: 14px;
    }
    .login-protect-text {
        color: #333333;
        text-align: center;
        font-size: 14px;
        margin-top: 8px;
    }
}