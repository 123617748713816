.employ-shell {
    width: 100%;
    margin-bottom: 60px;
    .employ-content {
        margin-top: 20px;
        width: 1199px;
        height: 481px;
        > img {
            width: 100%;
            height: 100%;
        }
    }
}