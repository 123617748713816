.footer-top {
    height: 113px;
    width: 100%;
    min-width: 1200px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid hsl(120deg 1.96% 90%);

    .footer-top-warrper {
        width: 1200px;
        min-width: 1200px;
        flex: none;
        display: flex;
        justify-content: space-between;

        .footer-top-item {
            display: flex;
            align-items: center;

            .footer-frame {
                width: 34px;
                height: 34px;
            }

            .footer-frame-name {
                // font-family: PingFang SC;
                font-size: 20px;
                font-weight: 400;
                margin-left: 9px;
            }
        }
    }
}

.footer-bottom {
    width: 100%;
    height: 61px;
    min-width: 1200px;
    display: flex;
    justify-content: center;
    align-items: center;

    .footer-bottom-warrper {
        width: 1200px;
        min-width: 1200px;
        flex: none;
        display: flex;
        align-items: center;
        justify-content: center;

        .footer-beian {
            width: 20px;
            height: 20px;
        }

        .footer-bottom-text {
            // font-family: PingFang SC;
            font-size: 14px;
            font-weight: 400;
            color: #A8A8A8;
            margin: 0 5px;
        }

        .footer_beian_img {
            margin-top: -4px;

            >img {
                margin-bottom: -2px;
            }
        }
    }
}

.footer-container {
    height: 332px;
    width: 100%;
    min-width: 1200px;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid hsl(120deg 1.96% 90%);

    .footer-container-warrper {
        height: 332px;
        width: 1200px;
        min-width: 1200px;
        flex: none;
        display: flex;
        justify-content: space-between;

        .footer-container-left {
            display: flex;
        }

        .footer-container-logoimg {
            width: 136px;
            height: 30.91px;
            margin-top: 60px;

            >img {
                width: 100%;
                height: 100%;
            }
        }

        .footer-container-navs {
            display: flex;
            margin: 60px 0 0 112px;

            .footer-container-title {
                // font-family: PingFang SC;
                font-size: 18px;
                font-weight: bold;
                color: #181818;
                padding-bottom: 10px;
                color: #181818;
            }

            .footer-container-nav {
                // font-family: PingFang SC;
                font-size: 16px;
                font-weight: 400;
                padding: 10px 0;
                color: #444444;
                cursor: pointer;
            }

            .footer-container-navs-right {
                margin-left: 70px;
            }
        }

        .footer-container-right {
            width: 431px;
            margin-top: 58px;

            .footer-phone-name {
                color: #5B5B5B;
                // font-family: PingFang SC;
                font-size: 14px;
                font-weight: 400;
            }

            .footer-phone-content {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;

                .footer-phone {
                    color: #000000;
                    // font-family: FZLanTingHeiS-DB1-GB;
                    font-size: 28px;
                    font-weight: bold;
                    line-height: 24px;
                }

                .footer-phone-btn {
                    width: 148px;
                    height: 40px;
                    border: 1px solid #E9623E;
                    border-radius: 20px;
                    color: #E9623E;
                    // font-family: FZLanTingHeiS-R-GB;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 38px;
                    text-align: center;
                }

                .footer-phone-btn:hover {
                    color: #fff;
                    background-color: #E9623E;
                    border-width: 0;
                }
            }

            .footer-wechats {
                margin-top: 45px;
                display: flex;
                justify-content: space-between;

                .footer-wechats-item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .footer-wechat-img {
                        width: 74px;
                        height: 74px;
                    }

                    .footer-wechat-text {
                        // font-family: PingFang SC;
                        margin-top: 3px;
                        font-size: 11px;
                        font-weight: 400;
                        line-height: 13px;
                        text-align: center;
                        color: #5B5B5B;
                    }
                }
            }
        }
    }
}