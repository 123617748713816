.header-top {
	display: flex;
	justify-content: space-between;
	height: 73px;
	align-items: center;

	.header-top-left {
		display: flex;
		align-items: center;

		.header-logo {
			width: 136px;
			height: 30.91px;
			margin-top: -12px;

			>img {
				width: 100%;
				height: 100%;
			}
		}

		.header-coordinate-wraaper {
			height: 26px;
			box-sizing: border-box;
			padding: 0 8.3px 0 10px;
			display: flex;
			align-items: center;
			margin-left: 10px;
			border-radius: 13px;
			background-color: #eff3f5;
			cursor: pointer;

			.header-coordinate {
				width: 12px;
				height: 13.33px;
			}

			.header-triangle {
				width: 8px;
				height: 5px;
			}
			.header-triangle-active{
				transform: rotate(180deg);
			}

			.header-coordinate-name {
				// font-family: PingFang SC;
				font-size: 14px;
				font-weight: 400;
				color: #666666;
				margin: 0 5px;
			}
		}
	}

	.header-top-right {
		display: flex;
		align-items: center;

		>.phone-icon {
			width: 30px;
			height: 30px;
		}

		>.header-phone {
			// font-family: FZLanTingHeiS-DB1-GB;
			font-size: 24px;
			font-weight: bold;
			letter-spacing: 0px;
			text-align: right;
			color: #17232F;
			margin-left: 6px;
		}
		.my-order-btn {
			width: 100px;
			height: 34px;
			color: #17232F;
			border: 1px solid #17232F;
			border-radius: 6px;
			font-size: 16px;
			font-weight: bold;
			line-height: 32px;
			text-align: center;
			margin-left: 39px;
			cursor: pointer;
		}
	}
}

.header-navs {
	height: 54px;
	display: flex;
	justify-content: space-between;
	margin-top: -5px;

	.header-nav {
		// font-family: FZLanTingHeiS-DB1-GB;
		font-size: 18px;
		// font-weight: 400;
		font-weight: bold;
		color: #17232F;
		position: relative;
		cursor: pointer;
	}

	.header-nav:hover {
		color: #E9623E !important;
	}

	.header-nav.header-nav-active {
		color: #E9623E;
	}

	.header-nav.header-nav-active::after {
		content: '';
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 4px;
		background-color: #E9623E;
		border-radius: 4px;
	}
}

.address-popover {
	width: 76px;
	padding: 2px 3px;
	box-sizing: border-box;
	.address-popover-item {
		font-family: PingFang SC;
		text-align: center;
		color: #17232F;
		border-bottom: 1px solid #ECEFF7;
		font-size: 14px;
		padding: 15px 0;
		cursor: pointer;
	}
	.address-popover-item.address-popover-item-active {
		color: #E9623E;
		font-weight: bold;
	}
}
.address-popover .address-popover-item:first-child {
	padding-top: 0;
}
.address-popover .address-popover-item:last-child {
	padding-bottom: 0;
	border-width: 0;
}