.my-order {
    width: 100%;

    .my-order-top {
        padding: 30px 0;
        margin-top: 2px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        background-color: #fff;

        .my-order-content {
            width: 1200px;
            min-width: 1200px;
            flex: none;
            padding-top: 0px;

            .breadcrumb-btn {
                // font-family: PingFang SC;
                font-size: 14px;
                font-weight: 400;
                cursor: pointer;
                color: #122431;
            }

            .breadcrumb-btn:hover {
                color: #E9623E;
            }
        }
    }

    .my-order-content {
        width: 1200px;
        min-width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        padding-top: 40px;
        box-sizing: border-box;

        .my-order-left {
            flex: none;
            width: 300px;

            .my-order-user {
                height: 270px;
                border-radius: 20px;
                border: 2px solid #fff;
                background-color: #fff;

                .my-order-user-bg {
                    width: 100%;
                    height: 270px;
                    background: url('https://img.58cdn.com.cn/dist/jxt/images/jxtschool/order_bg.png') no-repeat;
                    background-size: 100%;
                    padding-top: 64px;
                    box-sizing: border-box;
                }

                .user-img {
                    width: 100px;
                    height: 100px;
                    border: 2px solid #fff;
                    border-radius: 50%;
                    margin: 0 auto;

                    >img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .user-name {
                    font-size: 20px;
                    text-align: center;
                    color: #000;
                    margin-top: 20px;
                }

                .user-phone {
                    font-size: 14px;
                    text-align: center;
                    color: #666666;
                    margin-top: 8px;
                }
            }

            .my-order-title {
                height: 80px;
                background-color: #fff;
                border-radius: 20px;
                font-size: 20px;
                line-height: 80px;
                padding-left: 64px;
                margin-top: 20px;
                margin-bottom: 30px;
                position: relative;
            }

            .my-order-title::before {
                content: '';
                width: 4px;
                height: 24px;
                background-color: #E9623E;
                border-radius: 2px;
                position: absolute;
                top: 28px;
                left: 30px;
            }
        }

        .my-order-right {
            flex: none;
            width: 880px;
            border-radius: 20px;
            padding: 32px 20px 0;
            box-sizing: border-box;
            background-color: #fff;
            display: inline-block;
            margin-bottom: 91px;

            .order-title {
                font-size: 24px;
                font-weight: bold;
                padding-bottom: 22px;
                box-sizing: border-box;
                border-bottom: 1px solid #D9D9D9;
            }
            .order-list-item {
                height: 100px;
                padding-top: 16px;
                box-sizing: border-box;
                display: flex;
                justify-content: space-between;
                position: relative;
                .order-item-title {
                    display: flex;
                    align-items: center;
                    .order-item-cartype {
                        padding: 2px 8px;
                        border-radius: 6px;
                        background: linear-gradient(180deg, #FFA722 0%, #E96200 100%);
                        color: #fff;
                        font-size: 16px;
                        font-weight: bold;
                    }
                    .order-item-class {
                        color: #333333;
                        font-size: 20px;
                        font-weight: bold;
                        margin-left: 10px;
                    }
                }
                .order-item-name {
                    font-size: 14px;
                    color: #666666;
                    margin-top: 14px;
                }
                .order-item-right {
                    font-size: 36px;
                    color: #FF6600;
                    font-weight: bold;
                }
            }
            .order-list-item::before {
                content: '';
                width: 840px;
                border-bottom: 1px dashed #D9D9D9;
                position: absolute;
                right: 0;
                bottom: 0;
            }
            .order-list-item:last-child:before {
                width: 0;
            }
        }
    }
}