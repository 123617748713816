.swiper {
    width: 100%;
    height: 100%;
}


.swiper-slide {
    background-position: center;
    background-size: cover;
}
.mySwiper {
    width: 100%;
    min-width: 1200px;
    height: 435px;
    position: relative;
    .swiper-slide-item {
        width: 100%;
        min-width: 1200px;
        height: 435px;
        // background-color: #EBF0F6;
        display: flex;
        justify-content: center;
        cursor: pointer;
        > img {
            width: 1200px;
            min-width: 1200px;
        }
    }
    .swiper-slide-item-1 {
        background: url(https://img.58cdn.com.cn/dist/jxt/images/jxtschool/home_jx_banner_bg_13.png?v=1) center no-repeat;
        background-size: cover;
    }
    .swiper-slide-item-2 {
        // background: url(http://j1.58cdn.com.cn/dist/pcNewSite/images/home/banner-1-0.png) center no-repeat;
        // background-size: cover;
        background-color: #EBF0F6;
    }
    .swiper-pagination {
        position: absolute;
        bottom: 77px;
        left: 48%;
        display: inline-block;
        width: auto;
        display: flex;
        padding: 6px 10px;
        box-sizing: border-box;
        height: 20px;
        border-radius: 10px;
        background-color: rgba(0, 0, 0, 0.04);
        .swiper-pagination-bullet {
            width: 8px;
            height: 8px;
            // margin-right: 8px;
            background-color: rgba(255, 255, 255, 1);
            opacity: 0.5;
            border-radius: 4px;
            transition: all ease 0.3s;
        }
        .swiper-pagination-bullet.swiper-pagination-bullet-active {
            opacity: 1;
            width: 24px;
        }   
    }
}
