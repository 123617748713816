.my-pagination {

    .my-pagination-prve,
    .my-pagination-next {
        width: 64px;
        height: 32px;
        cursor: pointer;
        text-align: center;
        // font-family: PingFang SC;
        font-family: 微软雅黑;
        font-size: 14px;
        font-weight: 400;
        line-height: 32px;
        color: #444444;
        background-color: #F6F9FF;
    }
    .my-pagination-prve:hover,
    .my-pagination-next:hover {
        color: #fff;
        background-color: #17232F;
    }
    .my-pagination-page {
        width: 32px;
        height: 32px;
        cursor: pointer;
        text-align: center;
        // font-family: PingFang SC;
        font-family: 微软雅黑;
        font-size: 16px;
        font-weight: bold;
        line-height: 32px;
        color: #444444;
        background-color: #F6F9FF;
    }
    .my-pagination-page:hover {
        color: #fff;
        background-color: #17232F;
    }
    .ant-pagination-item {
        border: none;
    }
    .ant-pagination-item.ant-pagination-item-active  .my-pagination-page {
        color: #fff;
        background-color: #17232F;
    }
}