.title-shell {
    width: 100%;
    height: 71px;
    margin-top: 60px;

    .title-shell-title {
        // font-family: FZLanTingHeiS-DB1-GB;
        font-family: 微软雅黑;
        font-size: 34px;
        font-weight: bold;
        color: rgba(16, 29, 55, 1);
    }

    .title-shell-text-shell {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;

        .title-shell-text-left {
            // font-family: FZLanTingHeiS-R-GB;
            font-family: 微软雅黑;
            font-size: 14px;
            font-weight: 400;
            color: rgba(85, 85, 85, 1);
        }

        .title-shell-text-right {
            // font-family: FZLanTingHeiS-DB1-GB;
            font-family: 微软雅黑;
            font-size: 14px;
            font-weight: bold;
            color: rgba(69, 141, 210, 1);
            display: flex;
            align-items: center;
            cursor: pointer;
            >img {
                width: 8px;
                // height: 9px;
                margin-left: 2px;
                margin-bottom: 1px;
            }
        }
    }
}