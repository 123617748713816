.class-brief-item {
    width: 1200px;
    min-width: 1200px;
    // height: 167px;
    padding: 20px 0 20px 20px;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 1);
    margin-top: 20px;
    display: flex;
    cursor: pointer;

    .class-item-head-img {
        width: 194px;
        height: 146px;
        border-radius: 12px;
        overflow: hidden;
        margin-right: 20px;

        >img {
            width: 100%;
            height: 100%;
        }
    }

    .class-item-info {
        flex: 1;
        // margin-top: 26px;
        margin-right: 28px;

        .class-info-name {
            // font-family: FZLanTingHeiS-DB1-GB;
            font-family: 微软雅黑;
            font-size: 20px;
            font-weight: bold;
            color: #14213A;
            overflow: hidden;
            text-overflow: ellipsis; ///超出部分省略号显示
            display: -webkit-box !important; //弹性盒模型
            -webkit-box-orient: vertical; //上下垂直
            -webkit-line-clamp: 1; //自定义行数
        }

        .class-info-tabs {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 12px;

            .class-info-tab {

                .class-tab-item {
                    display: inline-block;
                    border-radius: 3px;
                    padding: 0 5px;
                    height: 20px;
                    box-sizing: border-box;
                    // font-family: FZLanTingHeiS-R-GB;
                    font-family: 微软雅黑;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 20px;
                    color: #666666;
                    background-color: #EFF3F5;
                }
            }
        }

        .class-info-remarks {
            margin-top: 12px;
            // font-family: FZLanTingHeiS-R-GB;
            font-family: 微软雅黑;
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            color: #666666;
            .class-remark-item {
                position: relative;
                padding-left: 14px;
            }
            .class-remark-item::before {
                content: '';
                position: absolute;
                left: 2px;
                top: 9px;
                display: inline-block;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background-color: #666666;
            }
        }

    }

    .class-item-right {
        display: flex;
        flex-direction: column;
        // justify-content: center;
        // align-items: center;
        margin-right: 28px;
        margin-top: 23px;

        .class-info-money {
            margin-left: -110px;
            margin-bottom: 12px;
            // font-family: PingFang SC;
            font-family: 微软雅黑;
            font-size: 28px;
            font-weight: bold;
            color: #E9623E;
            text-align: right;
        }

        .class-info-btn {
            width: 115px;
            height: 40px;
            border-radius: 20px;
            color: #fff;
            background-color: #E9623E;
            // font-family:  FZLanTingHeiS-DB1-GB;
            font-family: 微软雅黑;
            font-size: 16px;
            font-weight: bold;
            line-height: 40px;
            text-align: center;
            cursor: pointer;
        }

        .class-info-btn:hover {
            background-color: #D14F2C;
        }
    }
}

.class-brief-item:hover {
    margin-top: 19px;
    box-shadow: 5px 5px 20px 0px rgba(0, 78, 109, 0.1385);
}