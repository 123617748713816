.wisdom-shell {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .wisdom-shell-title {
        width: 1200px;
        min-width: 1200px;
        flex: none;
    }

    .wisdom-intro-content {
        width: 100%;
        height: 480px;
        margin-top: 20px;
        background-color: #EFF3F5;

        .wisdom-intro-content-bg {
            width: 100%;
            height: 100%;
            background: url(https://img.58cdn.com.cn/dist/jxt/images/jxtschool/wisdom_bg.png) no-repeat;
            background-size: cover;
            display: flex;
            justify-content: center;
        }

        .wisdom-intro-substance {
            flex: none;
            width: 1200px;
            min-width: 1200px;
            display: flex;
            justify-content: space-between;
            position: relative;

            .wisdom-swiper {
                width: 793px;
                height: 489px;
                margin-left: 407px;
                // overflow: hidden;
                overflow: initial;
                margin-top: -39px;
                .swiper-wrapper {
                    border-radius: 15px !important;
                    overflow: hidden;
                }
                .swiper-slide {
                    // width: 793px;
                    transition: all 0.8s ease-in-out;

                    /* 动画时间及缓动函数 */
                    >img {
                        border-radius: 15px !important;
                        width: 100%;
                        height: 100%;
                    }
                }
                .swiper-slide-img {
                    border-radius: 15px;
                }
                .wisdom-swiper-pagination {
                    position: absolute;
                    top: 35px;
                    left: -407px;
                }
            }

            .swiper-pagination {
                width: 402px;
                height: 480px;
                position: initial;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 35px 0 30px 0;
                box-sizing: border-box;

                .pagination-item {
                    width: 387px;
                    height: 124px;
                    border-radius: 15px;
                    background-color: #fff;
                    padding: 36px 0 0 29px;
                    box-sizing: border-box;
                    cursor: pointer;
                    transition: all ease-out 0.1s;

                    .pagination-item-title {
                        // font-family: FZLanTingHeiS-DB1-GB;
                        font-size: 28px;
                        font-weight: bold;
                        color: #101D37;
                        text-align: left;
                    }

                    .pagination-item-text {
                        // font-family: PingFang SC;
                        font-size: 18px;
                        font-weight: 400;
                        color: #9299A5;
                        margin-top: 8px;
                        text-align: left;
                    }
                }

                .pagination-item.pagination-item-active {
                    background: url(https://img.58cdn.com.cn/dist/jxt/images/jxtschool/wisdom_bg_1.png) no-repeat;
                    background-size: cover;
                    height: 128px;
                    width: 402px;

                    .pagination-item-title,
                    .pagination-item-text {
                        color: #fff;
                    }
                }
            }
        }
    }
}