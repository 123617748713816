.jx-news-detail {
    width: 100%;

    .jx-new-crumbs {
        width: 1200px;
        margin: auto;
        padding: 30px 0;
        // font-family: PingFang SC;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0px;
        text-align: left;
        
        .breadcrumb-btn {
            // font-family: PingFang SC;
            font-size: 14px;
            font-weight: 400;
            cursor: pointer;
            color: #122431;
        }
        .breadcrumb-btn:hover {
            color: #E9623E;
        }
    }

    .whiteBg {
        background-color: #FFF;
    }

    .new-detail {
        width: 1200px;
        margin: auto;
        padding: 30px 0;

        .new-header {

            .new-title {
                // font-family: PingFang SC;
                font-size: 36px;
                font-weight: 600;
                line-height: 40px;
                color: #101D37;
            }

            .new-date {
                // font-family: PingFang SC;
                font-size: 16px;
                margin-top: 10px;
                font-weight: 400;
                line-height: 22px;
                color: #6A6F77;
            }
        }

        .new-text {
            margin-top: 40px;
            p{
                text-align: center;
            }
        }
    }

    .new-next {
        padding: 30px 0;
        width: 1200px;
        margin: auto;
        // font-family: PingFang SC;
        font-size: 16px;
        font-weight: 600;
        line-height: 30px;
        cursor: pointer;
        color: #458DD2;
    }

}