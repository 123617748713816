.empty-list {
    width: 100%;
    margin: 20px 0;
    .empty-list-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 284px;
        height: 278px;
        margin: 0 auto;

        >img {
            width: 282px;
            height: 188px;
        }

        .empty-content-text {
            // font-family: FZLanTingHeiS-R-GB;
            font-family: 微软雅黑;
            font-size: 20px;
            font-weight: 400;
            color: #979797;
        }
    }
}