.coach-item {
    width: 383px;
    height: 167px;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 1);
    margin-top: 20px;
    display: flex;
    border: 2px solid rgba(255, 255, 255, 1);
    cursor: pointer;

    .coach-item-head-img {
        width: 84px;
        height: 84px;
        border-radius: 50%;
        margin: 42px 23px 0 30px;
        flex: none;
        overflow: hidden;
        >img {
            width: 100%;
            height: 100%;
        }
    }

    .coach-item-info {
        margin-top: 27px;

        .coach-info-top {
            display: flex;

            .coach-info-name {
                // font-family: FZLanTingHeiS-DB1-GB;
                font-family: 微软雅黑;
                font-size: 20px;
                font-weight: bold;
                color: #14213A;
                max-width: 100px;
                overflow: hidden;
                text-overflow: ellipsis; ///超出部分省略号显示
                display: -webkit-box !important; //弹性盒模型
                -webkit-box-orient: vertical; //上下垂直
                -webkit-line-clamp: 1; //自定义行数
            }

            .coach-info-realname {
                width: 62px;
                height: 20px;
                margin-left: 6px;

                >img {
                    width: 100%;
                    height: 100%;
                }
            }

            .coach-info-duration {
                margin-left: 6px;
                border-radius: 3px;
                padding: 0 5px;
                height: 20px;
                box-sizing: border-box;
                // font-family: FZLanTingHeiS-R-GB;
                font-family: 微软雅黑;
                font-size: 12px;
                font-weight: 400;
                line-height: 20px;
                color: #666666;
                background-color: #EFF3F5;
            }
        }

        .coach-info-cartype {
            display: flex;
            margin-top: 12px;

            >img {
                width: 18px;
                height: 18px;
            }

            .coach-info-cartype-text {
                margin-left: 11px;
                // font-family: FZLanTingHeiS-R-GB;
                font-family: 微软雅黑;
                font-size: 16px;
                font-weight: 400;
                color: #666666;
                overflow: hidden;
                text-overflow: ellipsis; ///超出部分省略号显示
                display: -webkit-box !important; //弹性盒模型
                -webkit-box-orient: vertical; //上下垂直
                -webkit-line-clamp: 1; //自定义行数
            }
        }

        .coach-info-evaluate {
            display: flex;
            margin-top: 12px;

            >img {
                width: 18px;
                height: 18px;
            }

            .coach-info-evaluate-text {
                margin-left: 11px;
                // font-family: FZLanTingHeiS-R-GB;
                font-family: 微软雅黑;
                font-size: 16px;
                font-weight: 400;
                color: #666666;

                .coach-info-text-number {
                    // font-family: FZLanTingHeiS-DB1-GB;
                    font-family: 微软雅黑;
                    font-size: 16px;
                    font-weight: bold;
                }
            }
        }

        .coach-info-tab {
            display: flex;
            margin-top: 12px;

            .coach-tab-item {
                border-radius: 3px;
                padding: 4px 5px;
                box-sizing: border-box;
                // font-family: FZLanTingHeiS-R-GB;
                font-family: 微软雅黑;
                font-size: 12px;
                font-weight: 400;
                line-height: 12px;
                color: #666666;
                background-color: #EFF3F5;
            }
        }
    }
}
.coach-item:hover {
    margin-top: 19px;
    background: url(https://img.58cdn.com.cn/dist/jxt/images/jxtschool/catch_active_img.png?v=1) center center no-repeat;
    background-size: cover;
    box-shadow: 5px 5px 20px 0px rgba(0, 78, 109, 0.1385);
    
}