.train-car-shell {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .train-car-bg {
        width: 100%;
        min-width: 1200px;
        height: 300px;
        background-color: #EBF0F6;
        // background: url(https://img.58cdn.com.cn/dist/jxt/images/jxtschool/banner_bg_1.png?v=2) center no-repeat;
        // background-size: cover;
        display: flex;
        justify-content: center;
        > img {
            width: 1200px;
            min-width: 1200px;
            height: 100%;
        }
    }

    .train-car-center {
        width: 1200px;
        min-width: 1200px;
        flex: none;

        .train-car-title {
            // font-family: PingFang SC;
            font-size: 34px;
            font-weight: 600;
            color: #101D37;
            margin-top: 60px;
        }
        .train-car-warrper {
            display: flex;
            flex-wrap: wrap;
            // justify-content: space-between;
            margin-top: 7px;

        }
        .train-car-page {
            display: flex;
            justify-content: flex-end;
            margin: 40px 0 60px;
        }
    }
}