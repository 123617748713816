.no-thave {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .no-thave-img {
        width: 400px;
        height: 280px;
        margin-top: 10%;
        > img {
            width: 100%;
            height: 100%;
        }
    }
    .no-thave-text {
        margin-top: 10px;
        color: #666;
    }
    .no-thave-btn {
        margin-top: 10px;
        cursor: pointer;
        color: aquamarine;
    }
}