.study-car-shell {
    width: 100%;

    .study-car-content {
        margin-top: 20px;
        width: 100%;
        height: 282px;
        display: flex;
        align-items: center;

        .study-car-content-bg {
            background-color: #fff;
            height: 260px;
            // border: 2px solid rgba(255, 255, 255, 1);
        }

        .study-car-content-bg-left {
            border-radius: 15px 0 0 15px;

            .study-car-item {
                border-radius: 15px 0 0 15px;
            }
        }

        .study-car-content-bg-center {
            .study-car-item.study-car-item-active {
                width: 294px;
                margin-left: -34px;
                padding-left: 82px;
            }
        }

        .study-car-content-bg-right {
            border-radius: 0 15px 15px 0;

            .study-car-item {
                border-radius: 0 15px 15px 0;
                overflow: hidden;
                width: 220px;
            }

            .study-car-item.study-car-item-active {
                width: 275px;
                margin-left: -34px;
                padding-left: 82px;
            }
        }

        .study-car-item {
            width: 240px;
            height: 260px;
            padding: 35px 0 0 52px;
            box-sizing: border-box;
            transition: all ease-out 0.1s;

            .study-car-item-title {
                // font-family: FZLanTingHeiS-B-GB;
                font-size: 28px;
                font-weight: bold;
                color: rgba(233, 98, 62, 1);
            }

            .study-car-content-top {
                margin-top: 28px;

                .study-car-content-title {
                    // font-family: FZLanTingHeiS-B-GB;
                    font-size: 20px;
                    font-weight: bold;
                    color: rgba(16, 29, 55, 1);
                }

                .study-car-content-text {
                    // font-family: FZLanTingHeiS-R-GB;
                    font-size: 16px;
                    font-weight: 400;
                    color: rgba(102, 102, 102, 1);
                    margin-top: 6px;
                }
            }

            .study-car-content-bottom {
                margin-top: 20px;

                .study-car-content-title {
                    // font-family: FZLanTingHeiS-B-GB;
                    font-size: 20px;
                    font-weight: bold;
                    color: rgba(16, 29, 55, 1);
                }

                .study-car-content-text {
                    // font-family: FZLanTingHeiS-R-GB;
                    font-size: 16px;
                    font-weight: 400;
                    color: rgba(102, 102, 102, 1);
                    margin-top: 6px;
                }
            }
        }

        .study-car-item.study-car-item-active {
            height: 282px;
            width: 260px;
            padding: 38px 0 0 56px;
            margin-top: -10px;

            .study-car-item-title {
                color: #fff;
            }

            .study-car-content-top {
                margin-top: 30px;
            }

            .study-car-content-bottom {
                margin-top: 22px;
            }

            .study-car-content-top,
            .study-car-content-bottom {
                .study-car-content-title {
                    color: #fff;
                }

                .study-car-content-text {
                    color: rgba(255, 208, 194, 1);
                }
            }

        }

        .study-car-item-bg1 {
            background: url(https://img.58cdn.com.cn/dist/jxt/images/jxtschool/car_1.png) right no-repeat;
            background-size: cover;
        }

        .study-car-item-bg2 {
            background: url(https://img.58cdn.com.cn/dist/jxt/images/jxtschool/car_1.png) right no-repeat;
            background-size: cover;
        }

        .study-car-item-bg3 {
            background: url(https://img.58cdn.com.cn/dist/jxt/images/jxtschool/car_2.png) right no-repeat;
            background-size: cover;
        }

        .study-car-item-bg1.study-car-item-active {
            background: url(https://img.58cdn.com.cn/dist/jxt/images/jxtschool/car_active_1.png) no-repeat;
            background-size: cover;
        }

        .study-car-item-bg2.study-car-item-active {
            background: url(https://img.58cdn.com.cn/dist/jxt/images/jxtschool/car_active_2.png) no-repeat;
            background-size: cover;
        }

        .study-car-item-bg3.study-car-item-active {
            background: url(https://img.58cdn.com.cn/dist/jxt/images/jxtschool/car_active_3.png) no-repeat;
            background-size: cover;
        }
    }
}