.jx-news {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;


    .jx-news-bg {
        width: 100%;
        min-width: 1200px;
        height: 300px;
        background-color: #EBF0F6;
        // background: url(https://img.58cdn.com.cn/dist/jxt/images/jxtschool/banner_bg_1.png?v=2) center no-repeat;
        // background-size: cover;
        display: flex;
        justify-content: center;
        > img {
            width: 1200px;
            min-width: 1200px;
            height: 100%;
        }
    }

    .jx-news-box {
        padding: 20px;
        height: 100%;
        margin-top: 40px;
        width: 1200px;

        .jx-news-tab {
            height: 54px;
            display: flex;

            .header-nav {
                // font-family: FZLanTingHeiS-DB1-GB;
                font-size: 28px;
                font-weight: bold;
                color: #17232F;
                position: relative;
                cursor: pointer;
                margin-right: 40px;
                line-height: 42px;
            }

            .header-nav:hover {
                color: #E9623E !important;
            }

            .header-nav.header-nav-active {
                color: #E9623E;
                font-size: 34px;
                line-height: 39px;
            }

            .header-nav.header-nav-active::after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 4px;
                background-color: #E9623E;
                border-radius: 4px;
            }
        }

        .jx-news-list {
            margin-top: 30px;

            .jx-new-item {
                display: flex;
                // box-shadow: 5px 5px 20px 0px #004E6D23;
                padding: 20px 15px;
                transition: all .3s;
                margin-bottom: 20px;
                height: 147px;
                background-color: #FFF;
                border-radius: 15px;
                .new-img {
                    width: 198px;
                    margin-right: 20px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .new-box {
                    width: calc(100% - 200px);
                    cursor: pointer;
                    .new-header {
                        display: flex;
                        margin-bottom: 10px;
                        justify-content: space-between;

                        .new-title {
                            display: flex;
                            .text {
                                // font-family: FZLanTingHeiS-DB1-GB;
                                font-size: 20px;
                                font-weight: bold;
                                line-height: 24px;
                                letter-spacing: 0px;
                                text-align: left;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                max-width: 750px;
                            }

                            .top {
                                background-color: #FFF6F1;
                                margin-left: 10px;
                                font-weight: bold;
                                // font-family: FZLanTingHeiS-R-GB;
                                font-size: 12px;
                                padding: 2px 5px;
                                text-align: right;
                                color: #E9623E;
                            }


                        }


                        .new-date {
                            margin-left: 20px;
                            // font-family: PingFang SC;
                            font-size: 14px;
                            font-weight: 400;
                            color: #6A6F77;
                            line-height: 20px;
                            letter-spacing: 0px;
                            text-align: right;

                        }
                    }

                    .new-text {
                        // font-family: FZLanTingHeiS-R-GB;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                        letter-spacing: 0px;
                        text-align: justified;
                        color: #14213A;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;

                    }
                }
                
            }
             .jx-new-item:hover{
                box-shadow: 5px 5px 20px 0px #004E6D23;
                transform: scale(1.01);
                .text{
                    color: #00C356;
                }
            }
        }
        .jx-news-pagination{
            margin-top: 20px;
            display: flex;
            justify-content: flex-end;
        }
    }

}