.training-cround {
    width: 285px;
    height: 317px;
    background-color: #fff;
    border-radius: 15px;
    overflow: hidden;
    cursor: pointer;

    .training-cround-img {
        width: 100%;
        height: 160px;

        >img {
            width: 100%;
            height: 100%;
        }
    }

    .training-cround-title {
        // font-family: FZLanTingHeiS-DB1-GB;
        font-family: 微软雅黑;
        font-size: 20px;
        font-weight: bold;
        color: rgba(20, 33, 58, 1);
        margin: 24px 0 0 20px;
        overflow: hidden;
        text-overflow: ellipsis; ///超出部分省略号显示
        display: -webkit-box !important; //弹性盒模型
        -webkit-box-orient: vertical; //上下垂直
        -webkit-line-clamp: 1; //自定义行数
    }

    .training-cround-cartype {
        margin: 14px 0 0 20px;
        display: flex;

        >img {
            width: 18px;
            height: 18px;
        }

        .training-cartype-text {
            // font-family: FZLanTingHeiS-R-GB;
            font-family: 微软雅黑;
            font-size: 16px;
            font-weight: 400;
            color: rgba(102, 102, 102, 1);
            margin-left: 10px;
            overflow: hidden;
            text-overflow: ellipsis; ///超出部分省略号显示
            display: -webkit-box !important; //弹性盒模型
            -webkit-box-orient: vertical; //上下垂直
            -webkit-line-clamp: 1; //自定义行数
        }
    }

    .training-cround-address {
        margin: 10px 0 0 20px;
        display: flex;

        >img {
            width: 18px;
            height: 18px;
        }

        .training-address-text {
            // font-family: FZLanTingHeiS-R-GB;
            font-family: 微软雅黑;
            font-size: 16px;
            font-weight: 400;
            color: rgba(102, 102, 102, 1);
            line-height: 24px;
            margin-left: 10px;
            margin-right: 19px;
            word-break: break-all;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }
}
.training-cround:hover {
    margin-top: -1px;
    box-shadow: 5px 5px 20px 0px rgba(0, 78, 109, 0.1385);
}