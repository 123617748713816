.home-synopsis {
    width: 1200px;
    min-width: 1200px;
    // height: 254px;
    padding-bottom: 30px;
    border: 3px solid rgba(255, 255, 255, 1);
    border-radius: 15px;
    margin-top: -62px;
    z-index: 999;
    background-color: #fff;
    overflow: hidden;
    > div {
        padding: 0 38px;
        height: calc(100% - 40px);
        display: flex;
        flex-direction: column;
        background: linear-gradient(180deg, #FFF6F3 -6.32%, #FFFFFF 100%), linear-gradient(0deg, #FFFFFF, #FFFFFF);
    }

    .home-synopsis-title {
        // font-family: FZLanTingHeiS-DB1-GB;
        font-size: 28px;
        font-weight: bold;
        color: rgba(233, 98, 62, 1);
        margin-top: 36px;
    }

    .home-synopsis-text {
        // font-family: FZLanTingHeiS-R-GB;
        font-size: 20px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0px;
        margin-top: 20px;
        color: #17232F;
        > div {
            text-indent: 2em;
        }
        .home-synopsis-text-cu {
            // font-family: FZLanTingHeiS-DB1-GB;
            font-weight: bold;
        }
    }
}
.home-center {
    width: 1200px;
    min-width: 1200px;
}