.star-coach-detail {
    width: 100%;

    .train-car-detail-top {
        padding: 30px 0;
        box-sizing: border-box;
        display: flex;
        justify-content: center;

        .train-detail-top-content {
            width: 1200px;
            min-width: 1200px;
            flex: none;

            .breadcrumb-btn {
                // font-family: PingFang SC;
                font-size: 14px;
                font-weight: 400;
                cursor: pointer;
                color: #122431;
            }
            .breadcrumb-btn:hover {
                color: #E9623E;
            }
        }
    }

    .train-car-detail-content {
        width: 100%;
        background-color: #fff;
        padding: 30px 0 40px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;

        .train-car-detail-shell {
            width: 1200px;
            min-width: 1200px;
            flex: none;
        }
        .introduce-content {
            width: 100%;
        }

        .coach-info-top {
            display: flex;

            .coach-info-top-img {
                width: 197px;
                height: 197px;
                border-radius: 50%;
                overflow: hidden;
                margin-right: 30px;

                >img {
                    width: 100%;
                    height: 100%;
                }
            }

            .coach-info-top-right {
                flex: 1;
                margin-top: 29px;


                .info-right-top {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;

                    .info-top-title {
                        display: flex;
                        align-items: center;

                        .coach-info-name {
                            // font-family: PingFang SC;
                            font-size: 36px;
                            font-weight: 600;
                            color: #101D37;
                        }

                        .coach-info-realname {
                            width: 76px;
                            height: 24px;
                            margin-left: 10px;
                            display: inline-block;
                       

                            >img {
                                width: 100%;
                                height: 100%;
                                margin-bottom: -4px;
                            }
                        }

                        .coach-info-duration {
                            margin-left: 9px;
                            border-radius: 5px;
                            display: inline-block;
                            padding: 0 8px;
                            height: 24px;
                            box-sizing: border-box;
                            // font-family: FZLanTingHeiS-R-GB;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 24px;
                            color: #666666;
                            background-color: #EFF3F5;
                            transform: translateY(-4px);
                        }

                    }

                    .info-top-vehicle {
                        display: flex;
                        margin-top: 21px;

                        >img {
                            width: 18px;
                            height: 18px;
                        }

                        .coach-info-cartype-text {
                            // font-family: FZLanTingHeiS-R-GB;
                            font-size: 16px;
                            font-weight: 400;
                            color: #666666;
                            margin-left: 11px;
                        }
                    }

                    .info-right-top-btn {
                        width: 242px;
                        height: 60px;
                        border-radius: 30px;
                        // font-family: PingFang SC;
                        font-size: 20px;
                        font-weight: bold;
                        line-height: 60px;
                        text-align: center;
                        color: #fff;
                        background-color: #E9623E;
                    }

                    .info-right-top-btn:hover {
                        background-color: #D14F2C;
                    }
                }

                .info-contacts {
                    display: flex;
                    margin-top: 12px;

                    >img {
                        width: 18px;
                        height: 18px;
                    }

                    .coach-info-evaluate-text {
                        margin-left: 11px;
                        // font-family: FZLanTingHeiS-R-GB;
                        font-size: 16px;
                        font-weight: 400;
                        color: #666666;
                        margin-right: 8px;

                        .coach-info-text-number {
                            // font-family: FZLanTingHeiS-DB1-GB;
                            font-size: 16px;
                            font-weight: bold;
                            color: #666666;
                        }
                    }
                }

                .info-address {
                    display: flex;
                    margin-top: 12px;

                    .coach-tab-item {
                        border-radius: 3px;
                        padding: 0 8px;
                        height: 24px;
                        box-sizing: border-box;
                        // font-family: FZLanTingHeiS-R-GB;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 26px;
                        color: #666666;
                        background-color: #EFF3F5;
                    }
                }
            }
        }

        .coach-info-introduce::before {
            content: '';
            top: -12px;
            left: 76px;
            border: 18px solid;
            border-color: #FFF6F3 transparent transparent transparent;
            transform: rotate(34deg);
            position: absolute;
        }

        .coach-info-introduce {
            width: 100%;
            position: relative;
            // min-height: 126px;
            margin-top: 32px;
            padding: 30px 30px 20px;
            box-sizing: border-box;
            background: linear-gradient(180deg, #FFF6F3 -6.32%, #FFFFFF 100%),linear-gradient(0deg, #FFFFFF, #FFFFFF);
            box-shadow: 5px 5px 20px 0px rgba(0, 78, 109, 0.03);
            border: 2px solid rgba(255, 255, 255, 1);
            border-radius: 15px;
            // background: url(https://img.58cdn.com.cn/dist/jxt/images/jxtschool/coach-info-introduce.png) center no-repeat;
            // background-size: 100% 100%;

            >div {
                // font-family: FZLanTingHeiS-DB1-GB;
                font-size: 20px;
                font-weight: bold;
                line-height: 32px;
                color: #666666;
            }
        }

        .introduce-title {
            // font-family: PingFang SC;
            font-size: 36px;
            font-weight: 600;
            line-height: 40px;
            color: #101D37;
            margin-top: 50px;
        }
        .train-car-page {
            display: flex;
            justify-content: flex-end;
            margin: 40px 0 60px;
        }
    }
}