.signup-page {
    width: 100%;

    .signup-page-bg {
        width: 100%;
        min-width: 1200px;
        height: 300px;
        background-color: #EBF0F6;
        // background: url(https://img.58cdn.com.cn/dist/jxt/images/jxtschool/banner_bg_1.png?v=2) center no-repeat;
        // background-size: cover;
        display: flex;
        justify-content: center;
        > img {
            width: 1200px;
            min-width: 1200px;
            height: 100%;
        }
    }

    .signup-page-box {
        width: 1200px;
        margin: auto;
        margin-bottom: 60px;

        .signup-title {
            padding: 60px 0 30px 0;
            // font-family: PingFang SC;
            font-size: 36px;
            font-weight: 600;
            line-height: 40px;
            text-align: left;

        }

        .signUp-notice {
          
            .notice-title {
                // font-family: FZLanTingHeiS-DB1-GB;
                font-size: 28px;
                font-weight: bold;
                line-height: 33px;
                letter-spacing: 0px;
                text-align: left;
            }

            .notice-content {
                ul {
                    list-style: none;

                    li {
                        // font-family: FZLanTingHeiS-R-GB;
                        font-size: 20px;
                        line-height: 32px;
                        letter-spacing: 0px;
                        text-align: justified;

                    }
                }
            }
        }

        .signUp-notice,
        .signup-process {
            width: 1200px;
            padding: 40px;
            border-radius: 20px;
            border: 3px solid #FFF;
            background: linear-gradient(180deg, #FFF6F3 -5.54%, #FFFFFF 87.62%),
                linear-gradient(0deg, #FFFFFF, #FFFFFF);
        }

        .signup-process {
            margin-top: 20px;
            .notice-process-title {
                // font-family: FZLanTingHeiS-DB1-GB;
                font-size: 28px;
                font-weight: bold;
                line-height: 33px;
                letter-spacing: 0px;
                text-align: left;
                margin-bottom: 20px;

            }


            .process-item {
                // border: 2px solid #000;
                display: flex;

                .process-title {
                    // width: 100px;
                    // max-width: 100px;
                    // font-family: FZLanTingHeiS-B-GB;
                    font-size: 28px;
                    font-weight: bold;
                    line-height: 33px;
                    color: #E9623E;
                    position: relative;
                    .process-title-name{
                        width: 60px;
                    }
                }

                .step-circle {
                    width: 20px;
                    position: absolute;
                    right: -31px;
                    top: 2px;
                    height: 20px;
                    border: 2px solid #BEC2C9;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .step-square {
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background-color: #BEC2C9;
                    }
                }



                .finally-step,.process-steps {
                    margin-left: 20px;
                    position: relative;
                    padding: 0 30px 20px;
                    // border-left: 2px solid #BEC2C9;

                    .step-item {
                        display: flex;
                        margin-bottom: 20px;
                        flex-direction: column;
                        .step-header {
                            display: flex;
                            .step-name {
                                // font-family: FZLanTingHeiS-B-GB;
                                font-size: 20px;
                                font-weight: bold;
                                line-height: 24px;
                                color: #E9623E;
                                position: relative;
                            }
                            .second-sauqre::before{
                                content: '';
                                display: block;
                                position: absolute;
                                top: 2px;
                                left: -35px;
                                background-color: #BEC2C9;
                                width: 13px;
                                height: 13px;
                                border-radius: 50%;
                            }
                            .gray {
                                color: #000;
                            }
                            .step-date {
                                margin-left: 20px;
                                // font-family: FZLanTingHeiS-R-GB;
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 24px;
                                color: #666666;
                            }
                        }
                        .step-notice {
                            width: 983px;
                            padding: 20px;
                            background-color: #F9F9F9;
                            margin-top: 10px;
                            color: #666666;
                            font-size: 16px;

                            span {
                                margin: 0 10px;
                            }
                        }
                    }
                   
                }
               

                .process-steps::before {
                    content: '';
                    top: 20px;
                    left: 0px;
                    width: 2px;
                    height: calc(100% - 17px);
                    display: block;
                    position: absolute;
                    // height: 100%;
                    background-color: #BEC2C9;
                }
            }
        }
    }

}